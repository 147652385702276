<template>
  <v-content sticky-container>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-top justify-left>
        <v-flex xs12 sm4 md4 lg5 xl3>
          <!-- Medio de venta -->
          <template v-if="_.includes(this.steps, 1)">
            <v-subheader style="position: relative;">
              1 - Cliente atendido
              <span
                v-if="order.type !== null"
                class="success--text ml-2 font-weight-bold"
                v-html="order.type === 0 ? 'Remoto' : 'En Tienda'"
              >
              </span>
              <v-btn
                v-if="order.type !== null"
                color="success"
                icon
                right
                absolute
                small
                @click="edit.type = !edit.type"
              >
                <v-icon v-if="!edit.type" small> edit </v-icon>
                <v-icon v-else small> close </v-icon>
              </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-layout v-if="order.type === null || edit.type" class="pa-0">
              <v-flex xs6>
                <v-btn color="accent" style="height:60px;" block depressed large @click="handleType(0)">
                  <v-icon left dark>
                    phone
                  </v-icon>
                  Remoto
                </v-btn>
              </v-flex>
              <v-flex xs6>
                <v-btn color="accent" style="height:60px;" block depressed large @click="handleType(1)">
                  <v-icon left dark>store</v-icon>
                  En Tienda
                </v-btn>
              </v-flex>
            </v-layout>
          </template>

          <!-- Tipo de venta -->
          <template v-if="_.includes(this.steps, 2)">
            <v-subheader style="position: relative;">
              2 - Tipo de entrega
              <span
                v-if="order.shipping !== null"
                class="success--text ml-2 font-weight-bold"
                v-html="order.shipping === 0 ? 'Despacho' : 'Retira'"
              >
              </span>
              <v-btn
                v-if="order.shipping !== null"
                color="success"
                icon
                right
                absolute
                small
                @click="edit.shipping = !edit.shipping"
              >
                <v-icon v-if="!edit.shipping" small> edit </v-icon>
                <v-icon v-else small> close </v-icon>
              </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-layout v-if="order.shipping === null || edit.shipping" class="pa-0">
              <v-flex xs6>
                <v-btn color="secondary" style="height:60px;" block depressed large @click="handleClickShipping(0)">
                  <v-icon left dark>local_shipping</v-icon>
                  Con Despacho
                </v-btn>
              </v-flex>
              <v-flex xs6>
                <v-btn color="secondary" style="height:60px;" block depressed large @click="handleClickShipping(1)">
                  <v-icon left dark>store</v-icon>
                  Retira cliente
                </v-btn>
              </v-flex>
            </v-layout>
          </template>

          <!-- Retiro en Local -->
          <template v-if="_.includes(this.steps, 3) && this.order.shipping == 1">
            <v-subheader style="position: relative;">
              3 - Tiendas disponibles
              <span
                v-if="order.shipping !== null"
                class="success--text ml-2 font-weight-bold"
                v-html="_.size(order.store) > 0 ? order.store.name : ''"
              >
              </span>
              <v-btn
                v-if="_.size(order.store) > 0"
                color="success"
                icon
                right
                absolute
                small
                @click="edit.store = !edit.store"
              >
                <v-icon v-if="!edit.store" small> edit </v-icon>
                <v-icon v-else small> close </v-icon>
              </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-layout v-if="_.size(order.store) === 0 || edit.store" class="pa-0">
              <v-flex xs12>
                <v-card class="mt-2 mb-0" scrollable>
                  <v-card-text class="pa-0" style="height: 200px;overflow: scroll;">
                    <v-list v-if="storesRetirements.length > 0" two-line class="pa-0">
                      <template v-for="(store, index) in storesRetirements">
                        <v-list-tile :key="index" @click="handleClickStore(store)">
                          <v-list-tile-action>
                            <v-icon v-if="store.id === order.store.id" color="accent">
                              check_box
                            </v-icon>
                            <v-icon v-else>
                              check_box_outline_blank
                            </v-icon>
                          </v-list-tile-action>
                          <v-list-tile-content>
                            <v-list-tile-title v-html="store.name"> </v-list-tile-title>
                            <v-list-tile-sub-title v-html="`${store.address}, ${store.state.name}`">
                            </v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action> </v-list-tile-action>
                        </v-list-tile>
                        <v-divider :key="`div-${index}`"></v-divider>
                      </template>
                    </v-list>
                    <div v-else>
                      <v-alert :value="true" type="warning" class="ma-0">
                        No hay comunas con los terminos buscados
                        {{ searchText }}
                      </v-alert>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </template>

          <!-- Comuna de entrega -->
          <template v-if="_.includes(this.steps, 3) && this.order.shipping == 0">
            <v-subheader style="position: relative;">
              3 - Comuna de entrega
              <span
                v-if="_.size(order.state) > 0"
                class="success--text ml-2 font-weight-bold"
                v-html="_.size(order.state) > 0 ? order.state.name : ''"
              >
              </span>
              <v-btn v-if="_.size(order.state) > 0" color="success" icon right absolute small @click="edit.state = {}">
                <v-icon v-if="!edit.state" small> edit </v-icon>
                <v-icon v-else small> edit</v-icon>
              </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-layout v-if="_.size(order.state) === 0 || edit.state" class="pa-0">
              <v-flex>
                <v-text-field
                  v-model="searchText"
                  hide-details
                  append-icon="search"
                  placeholder="Buscar por nombre"
                  right
                  solo
                  autocomplete="off"
                  @keyup.native="searchInputHandle"
                ></v-text-field>
                <v-card class="mt-2 mb-0" scrollable>
                  <v-card-text class="pa-0" style="height: 300px;overflow: scroll;">
                    <v-list v-if="states.length > 0" two-line class="pa-0">
                      <template v-for="(state, index) in states">
                        <v-list-tile :key="index" @click="handleClickState(state)">
                          <v-list-tile-action>
                            <v-icon v-if="state.id === order.state.id" color="accent">
                              check_box
                            </v-icon>
                            <v-icon v-else>check_box_outline_blank</v-icon>
                          </v-list-tile-action>
                          <v-list-tile-content>
                            <v-list-tile-title v-html="state.name"></v-list-tile-title>
                            <v-list-tile-sub-title v-if="!state.shipping" v-html="`${state.timeFormat} hrs`">
                            </v-list-tile-sub-title>
                            <v-list-tile-sub-title v-else v-html="`Quedan ${$moment.unix(state.time).toNow(true)}`">
                            </v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-chip v-if="!state.shipping" color="red" text-color="white">
                              No hay reparto para hoy
                            </v-chip>
                          </v-list-tile-action>
                        </v-list-tile>
                        <v-divider :key="`div-${index}`"></v-divider>
                      </template>
                    </v-list>
                    <div v-else>
                      <v-alert :value="true" type="warning">
                        No hay comunas con los terminos buscados.
                      </v-alert>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </template>

          <!-- Fecha de entrega o retiro -->
          <template v-if="_.includes(this.steps, 4)">
            <v-subheader style="position: relative;">
              4 -
              <span v-if="order.shipping" class="ml-1"> Fecha de retiro</span>
              <span v-else class="ml-1"> Fecha de entrega</span>
              <span
                v-if="order.dateShipping !== null"
                class="success--text ml-2 font-weight-bold"
                style="text-transform: capitalize;"
                v-html="$moment(order.dateShipping).format('dddd DD-MM-YYYY')"
              >
              </span>
              <v-btn
                v-if="order.dateShipping !== null"
                color="success"
                icon
                right
                absolute
                small
                @click="edit.dateShipping = true"
              >
                <v-icon v-if="!edit.dateShipping" small> edit</v-icon>
                <v-icon v-else small> edit</v-icon>
              </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-date-picker
              v-if="order.dateShipping === null || edit.dateShipping"
              v-model="order.dateShipping"
              class="my-4"
              locale="es-es"
              :landscape="$mq === 'xl'"
              full-width
              :reactive="true"
            >
            </v-date-picker>
          </template>

          <!-- Horario de entrega -->
          <template v-if="_.includes(this.steps, 5)">
            <v-subheader style="position: relative;">
              5 - Horarios disponibles
              <span
                v-if="order.schedule !== null"
                class="success--text ml-2 font-weight-bold"
                style="text-transform: capitalize;"
                v-html="order.schedule.name"
              >
              </span>
              <v-btn
                v-if="_.size(order.schedule) > 0"
                color="success"
                icon
                right
                absolute
                small
                @click="edit.schedule = !edit.schedule"
              >
                <v-icon v-if="!edit.schedule" small> edit </v-icon>
                <v-icon v-else small> close </v-icon>
              </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-layout v-if="_.size(order.schedule) === 0 || edit.schedule" class="pa-0">
              <v-flex>
                <v-card class="mt-2 mb-0" scrollable>
                  <v-card-text class="pa-0" style="height: 300px;overflow: scroll;">
                    <v-list v-if="schedules.length > 0" two-line class="pa-0">
                      <template v-for="(schedule, index) in schedules">
                        <v-list-tile :key="index" @click="order.schedule = schedule">
                          <v-list-tile-action>
                            <v-icon v-if="schedule.id === order.schedule.id" color="accent">
                              check_box
                            </v-icon>
                            <v-icon v-else>check_box_outline_blank</v-icon>
                          </v-list-tile-action>
                          <v-list-tile-content>
                            <v-list-tile-title v-html="schedule.name"></v-list-tile-title>
                            <v-list-tile-sub-title
                              v-html="`Con un valor de ${$options.filters.toPrice(schedule.price)}`"
                            >
                            </v-list-tile-sub-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-divider :key="`div-${index}`"></v-divider>
                      </template>
                    </v-list>
                    <v-alert
                      v-else
                      :value="true"
                      type="warning"
                      class="ma-0"
                      v-html="
                        `No hay horarios disponibles para
                        ${order.state.name} el <br>
                        ${$options.filters.capitalize($moment(order.dateShipping).format('dddd DD-MM-YYYY'))}`
                      "
                    >
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </template>

          <!-- Dirección de entrega -->
          <template v-if="_.includes(this.steps, 6) && order.shipping === 0">
            <v-subheader style="position: relative;">
              6 - Dirección de entrega
              <span
                v-if="order.address.latitude !== null && order.address.longitude !== null"
                class="success--text ml-2 font-weight-bold"
                style="text-transform: capitalize;"
                v-html="`${order.address.street} ${order.address.number}`"
              >
              </span>
              <v-btn
                v-if="order.address.latitude !== null && order.address.longitude !== null"
                color="success"
                icon
                right
                absolute
                small
                @click="edit.address = !edit.address"
                @mouseover="hover.address = true"
                @mouseleave="hover.address = false"
              >
                <v-icon v-if="!edit.address" small> edit </v-icon>
                <v-icon v-else small> close </v-icon>
              </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-layout
              v-if="(order.address.latitude === null && order.address.longitude === null) || edit.address"
              class="pa-0"
              wrap
            >
              <v-flex v-if="filterAddresses().length > 0" class="pb-0" xs12 sm12 md12>
                <v-select
                  v-model="addressSelection"
                  :hint="`${addressSelection.more}`"
                  :item-text="
                    item => {
                      const lastname = item.lastname != '' ? ' ' + item.lastname + ' - ' : ' - '
                      return `${item.name}${lastname}${item.street} ${item.number}`
                    }
                  "
                  :items="filterAddresses()"
                  label="Libreta de direcciones"
                  return-object
                >
                  <template slot="selection">
                    <span
                      v-html="
                        `${addressSelection.name},
                        ${addressSelection.street}
                        ${addressSelection.number}`
                      "
                    >
                    </span>
                  </template>
                </v-select>
              </v-flex>
              <v-flex class="pb-0" xs12 sm12 md12>
                <v-text-field
                  v-model="order.address.name"
                  label="Destinatario"
                  placeholder="Nombre Apellido"
                  hide-details
                >
                </v-text-field>
              </v-flex>
              <v-flex class="pb-0" xs12 sm12 md12>
                <v-text-field
                  v-model="order.address.phone"
                  label="Telefono (no obligatorio)"
                  placeholder="+56 2 2570 9510"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex class="pb-0" xs12>
                <v-select
                  v-model="order.address.type"
                  :items="typesAddress"
                  item-id="id"
                  item-text="name"
                  item-value="id"
                  label="Tipo dirección"
                  hide-details
                >
                </v-select>
              </v-flex>
              <v-flex
                v-if="
                  listTypesAddress.length > 0 &&
                    (order.address.type === 'CrClinica' || order.address.type === 'CrVelatorio')
                "
                class="pb-0"
                xs12
              >
                <v-select
                  v-model="typesAddressSelect"
                  :items="listTypesAddress"
                  item-text="name"
                  :label="
                    order.address.type === 'CrClinica' ? 'Listado de Clinicas' : 'Listado de Iglesias o Velatorios'
                  "
                  hide-details
                  return-object
                ></v-select>
              </v-flex>
              <v-flex v-if="order.address.type === 'CrClinica'" class="pb-0" xs12>
                <v-radio-group v-model="order.address.typeHospital" row hide-details>
                  <v-radio label="Paciente" value="paciente"></v-radio>
                  <v-radio label="Funcionario" value="funcionario"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex class="pb-0" xs12 sm8 md8>
                <v-text-field
                  v-model="order.address.street"
                  label="Dirección"
                  placeholder="Cautin"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex class="pb-0" xs12 sm4 md4>
                <v-text-field
                  v-model="order.address.number"
                  label="Numero"
                  placeholder="879"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex class="pb-0" xs12>
                <v-text-field v-model="order.address.more" label="Mas información" hide-details></v-text-field>
              </v-flex>
              <v-flex class="pb-0" xs12>
                <GmapAutocomplete
                  ref="search-map"
                  class="input-search"
                  style="width:100%;"
                  :country="['cl']"
                  :value="addressComplete"
                  @place_changed="setPlace"
                >
                </GmapAutocomplete>
              </v-flex>
              <v-flex xs12>
                <gmap-map
                  id="map"
                  ref="myMap"
                  :center="center"
                  :zoom="zoom"
                  :options="options"
                  :style="`width:100%;height:${windowHeight}px;`"
                >
                  <GmapMarker :position="latLngOrder" :clickable="false" :draggable="true" @dragend="dragend" />
                </gmap-map>
              </v-flex>
              <v-flex>
                <v-btn :disabled="_.size(latLngOrder) === 0" color="primary" class="ma-0" block @click="insertAddress">
                  Ingresar
                </v-btn>
              </v-flex>
            </v-layout>
          </template>

          <!-- Forma de pago -->
          <template v-if="_.includes(this.steps, 7)">
            <v-subheader style="position: relative;">
              {{ order.shipping === 0 ? '7' : '6' }} - Forma de pago
              <span
                v-if="_.size(order.paymentMethod) > 0"
                class="success--text ml-2 font-weight-bold"
                style="text-transform: capitalize;"
                v-html="order.paymentMethod.name"
              >
              </span>
              <v-btn
                v-if="_.size(order.paymentMethod) > 0"
                color="success"
                icon
                right
                absolute
                small
                @click="edit.payment = !edit.payment"
              >
                <v-icon v-if="!edit.payment" small> edit </v-icon>
                <v-icon v-else small> close </v-icon>
              </v-btn>
            </v-subheader>
            <v-layout v-if="_.size(order.paymentMethod) === 0 || edit.payment" class="pa-0">
              <v-flex>
                <v-card class="mt-2 mb-0" scrollable>
                  <v-card-text class="pa-0" style="height: 300px;overflow-y: scroll;">
                    <v-list class="pa-0">
                      <template v-for="(payment, index) in methodsPayment">
                        <v-list-tile :key="index" @click="order.paymentMethod = payment">
                          <v-list-tile-action>
                            <v-icon v-if="payment.id === order.paymentMethod.id" color="accent">
                              check_box
                            </v-icon>
                            <v-icon v-else>
                              check_box_outline_blank
                            </v-icon>
                          </v-list-tile-action>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ payment.name }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-divider :key="`div-${index}`"></v-divider>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        </v-flex>
        <v-flex xs12 sm8 md8 lg7 xl9>
          <v-layout row wrap>
            <v-flex xs12>
              <v-subheader
                >Datos del cliente <v-spacer></v-spacer>
                <v-btn
                  v-if="order.user.id === null || order.user.name === undefined"
                  class="ma-0"
                  color="primary"
                  flat
                  small
                  @click="dialogUser = true"
                  >¿Es cliente?</v-btn
                >
                <v-btn v-else class="ma-0" color="primary" flat small @click="deleteUser">
                  <span v-html="`Pedido para ${order.user.name}`"></span>
                  <v-icon right> close </v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 sm6 md3>
              <v-text-field
                v-model="order.user.name"
                :readonly="order.user.id != null"
                label="Nombre"
                placeholder="Floreria Rosalinda"
                hide-details
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3>
              <v-text-field
                v-model="order.user.email"
                :readonly="order.user.id != null"
                label="Email"
                placeholder="contacto@rosalinda.cl"
                hide-details
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3>
              <v-text-field v-model="order.user.phone" label="Teléfono" placeholder="+56 2 2570 9510" hide-details>
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3>
              <v-switch v-model="order.typeShipping" hide-details :label="'Entrega ANONIMA'" />
            </v-flex>
            <v-flex v-if="!order.user.status && order.user.status !== undefined" xs12>
              <v-alert type="error" :value="true">
                <p>Usuario bloqueado para recibir pedidos</p>
                <p v-if="order.user.alert !== ''" v-html="order.user.alert"></p>
              </v-alert>
            </v-flex>
            <v-flex v-else-if="order.user.alert !== '' && order.user.status" xs12>
              <v-alert type="info" :value="order.user.alert !== '' && order.user.alert !== null">
                <p v-html="order.user.alert"></p>
              </v-alert>
            </v-flex>
            <v-flex v-if="this.order.dateShipping === null && this._.size(this.order.state) === 0" xs12>
              <v-alert :value="true" type="info">
                Debe seleccionar comuna y fecha de entrega, para buscar productos
              </v-alert>
            </v-flex>
            <v-flex v-else xs12 sm12 md12>
              <v-card>
                <titleCard title="Carro de compras" :subtitle="`${order.products.length} productos en el carro`">
                  <template slot="button">
                    <v-text-field
                      v-model="searchTextProduct"
                      :hint="!typeSearch ? '¿Buscar por codigo? click en el icono :)' : ''"
                      :prepend-icon="!typeSearch ? 'insert_comment' : 'code'"
                      append-icon="search"
                      :placeholder="!typeSearch ? 'Buscar por nombre' : 'Buscar por codigo EJ: 1234'"
                      flat
                      single-line
                      right
                      @click:prepend="typeSearch = !typeSearch"
                      @keyup.native="searchInputProduct"
                    ></v-text-field>
                  </template>
                </titleCard>
                <v-divider></v-divider>
                <v-layout wrap class="px-3">
                  <template v-for="(product, index) in products">
                    <v-flex :key="index" xs12 sm3 lg4 xl2>
                      <v-card color="secondary" class="white--text mb-1 elevation-0">
                        <v-img :src="product.img.url" :lazy-src="`https://picsum.photos/10/6`" class="lighten-2" />
                        <v-card-text>
                          <v-layout wrap row>
                            <v-flex xs12>
                              <div>
                                <div class="subheading font-weight-regular cut-text">
                                  <a
                                    href=""
                                    class="white--text"
                                    style="text-decoration: none;cursor: auto;"
                                    :title="product.name"
                                    v-html="product.name"
                                  ></a>
                                </div>
                                <div class="caption">(P{{ product.id }})</div>
                              </div>
                            </v-flex>
                            <v-flex xs12 class="pt-0">
                              <div v-if="product.price.oferta">
                                Antes: <br />
                                <strike class="captio strike"> {{ product.price.old | toPrice }} </strike><br />
                                Ahora:
                                <span class="headline">
                                  {{ product.price.new | toPrice }}
                                </span>
                              </div>
                              <div v-else>
                                <span class="headline">
                                  {{ product.price.old | toPrice }}
                                </span>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                        <v-divider light></v-divider>
                        <v-card-actions>
                          <v-btn :href="product.url" target="_blank" class="ma-0" icon>
                            <v-icon color="white">launch</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn class="ma-0 accent white--text" flat icon @click="addProduct(product)">
                            <v-icon>add_shopping_cart</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-flex>
                  </template>
                </v-layout>
                <v-data-table
                  :headers="headers"
                  :items="order.products"
                  class="elevation-0"
                  no-data-text="Agregue productos al carro"
                  hide-actions
                >
                  <template slot="items" slot-scope="props">
                    <td>
                      <v-layout align-center justify-center row>
                        <v-flex xs3>
                          <v-avatar v-if="props.item.type === 0" :tile="false" :size="42" color="grey lighten-4">
                            <img :src="props.item.detail.img.url" alt="avatar" />
                          </v-avatar>
                          <v-avatar v-else :tile="false" :size="42" color="primary" class="white--text">
                            A
                          </v-avatar>
                        </v-flex>
                        <v-flex class="text-xs-left" xs9>
                          <v-edit-dialog
                            :return-value.sync="props.item.detail.name"
                            lazy
                            large
                            cancel-text="Volver"
                            save-text="Guardar"
                          >
                            {{ props.item.detail.name }}
                            <template slot="input">
                              <v-text-field v-model="props.item.detail.name" label="Edit" single-line></v-text-field>
                            </template>
                          </v-edit-dialog>
                          <v-alert
                            :value="props.item.comment !== null && props.item.comment !== undefined"
                            color="info"
                            class="mt-1 pa-1 caption"
                            outline
                          >
                            {{ props.item.comment }}
                          </v-alert>
                        </v-flex>
                      </v-layout>
                    </td>
                    <td class="text-xs-right">
                      <v-edit-dialog
                        persistent
                        :return-value.sync="props.item.quantity"
                        lazy
                        large
                        cancel-text="Volver"
                        save-text="Guardar"
                        @save="changeQuantityProduct(props, 'quantity')"
                      >
                        <span>{{ props.item.quantity }}</span>
                        <template slot="input">
                          <v-text-field
                            v-model="props.item.quantity"
                            type="number"
                            label="Cantidad"
                            single-line
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td class="text-xs-right">
                      <template v-if="props.item.type === 1">
                        <v-edit-dialog
                          persistent
                          :return-value.sync="props.item.unity"
                          lazy
                          large
                          cancel-text="Volver"
                          save-text="Guardar"
                          @save="changeQuantityProduct(props, 'valor')"
                        >
                          <div>{{ props.item.unity | toPrice }}</div>
                          <template slot="input">
                            <v-text-field
                              v-model="props.item.unity"
                              type="number"
                              label="Valor unitario"
                              single-line
                              autofocus
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-else>
                        {{ props.item.unity | toPrice }}
                      </template>
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.total | toPrice }}
                    </td>
                    <td class="text-xs-right">
                      <v-btn v-if="props.item.type !== 1" icon color="info" small @click.stop="addComment(props)">
                        <v-icon small> add_comment </v-icon>
                      </v-btn>
                      <v-icon small @click="deleteItemAction(props)">
                        delete
                      </v-icon>
                    </td>
                  </template>
                  <template slot="footer">
                    <tr>
                      <td :colspan="3" class="text-xs-right">
                        <strong> Productos: </strong>
                      </td>
                      <td :colspan="1" class="text-xs-right">
                        {{ orderProductos | toPrice }}
                      </td>
                      <td :colspan="1"></td>
                    </tr>
                    <tr>
                      <td :colspan="3" class="text-xs-right">
                        <strong> Envío: </strong>
                      </td>
                      <td v-if="order.shipping === 0" :colspan="1" class="text-xs-right">
                        {{ this._.size(this.order.schedule) > 0 ? order.schedule.price : 0 | toPrice }}
                      </td>
                      <td v-if="order.shipping === 1" :colspan="1" class="text-xs-right">
                        0
                      </td>
                      <td :colspan="1" class="text-xs-right"></td>
                    </tr>
                    <tr>
                      <td :colspan="1" class="text-xs-left">
                        <v-btn class="ma-0" color="accent" depressed small @click="addAditional">Adicional</v-btn>
                      </td>
                      <td :colspan="2" class="text-xs-right">
                        <strong> Total: </strong>
                      </td>
                      <td :colspan="1" class="text-xs-right">
                        {{ orderTotal | toPrice }}
                      </td>
                      <td :colspan="1"></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>
            <v-flex xs12>
              <v-subheader>
                Mensaje en la tarjeta (opcional)
                <v-spacer></v-spacer>
                <v-btn color="primary" flat small @click="openMessage">
                  ¿Mensajes predefinidos?
                  <v-icon v-if="dialogMessage" right> close </v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
            </v-flex>
            <v-flex v-if="dialogMessage" xs12>
              <v-autocomplete
                v-model="order.message"
                :items="messages"
                label="Mensajes predefinidos"
                persistent-hint
                item-text="msj"
                item-value="msj"
                hint="Si selecciona un mensaje, se borra el mensaje que esta escrito"
                autofocus
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-tile-content>
                    <v-list-tile-title v-html="item.msj"></v-list-tile-title>
                    <v-list-tile-sub-title v-text="item.type"></v-list-tile-sub-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12>
              <QuillEditorFormat
                v-model="order.message"
                :reference="'newMessageOrder'"
                placeholder="Mensaje de la tarjeta"
              ></QuillEditorFormat>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="dialogOrderPre" width="500">
      <v-card>
        <v-toolbar class="elevation-0" color="secondary" dark>
          <v-toolbar-title class="font-weight-regular">Tiene una orden precargada</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="body-1">
          Desea usar esta misma orden o hacer una nueva
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn flat @click="dialogOrderPre = false">
            Usar la misma
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="clearOrder">
            Crear nueva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUser" width="500">
      <v-card>
        <v-toolbar class="elevation-0" color="secondary" dark>
          <v-toolbar-title class="font-weight-regular">Buscar cliente</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="body-1">
          <v-text-field
            v-model="searchTextEmail"
            hide-details
            append-icon="search"
            placeholder="Buscar por email o nombre"
            right
            single-line
            box
            autocomplete="off"
            @keyup.native="searchInputUser"
          ></v-text-field>
          <v-list v-if="this.listUser.length > 0" two-line>
            <template v-for="(item, index) in this.listUser">
              <v-list-tile :key="index" avatar @click="handleClickUser(item)">
                <v-list-tile-avatar>
                  <AvatarLetter :text="item.name" :color="!item.status ? 'error' : 'secondary'" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title v-html="item.name"></v-list-tile-title>
                  <v-list-tile-sub-title>
                    <span class="text--primary" v-html="item.email"></span>
                    |
                    <span class="caption"> Ultimo inicio sesion el {{ item.fecha_up }} </span>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogComentario" width="500" persistent>
      <v-card v-if="productIndex !== null">
        <AppTitleDialog
          title="Agregar comentario adicional"
          close
          :close-action="
            () => {
              dialogComentario = false
            }
          "
        ></AppTitleDialog>
        <v-card-text>
          <v-textarea v-model="order.products[productIndex].comment" label="Comentario" hide-details></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="back" flat @click.stop="dialogComentario = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.stop="dialogComentario = false">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn color="secondary" fab fixed right bottom dark @click="finishOrder">
      <v-icon>send</v-icon>
    </v-btn>
    <OrderEmail
      :id="orderID"
      :email="orderEmail"
      :dialog="orderDialog"
      :finish="finish"
      :close="() => (this.orderDialog = false)"
    ></OrderEmail>
  </v-content>
</template>

<script>
import {
  GET_API,
  GET_STATUS_STORE,
  GET_SEARCH_SCHEDULE,
  GET_SEARCH_PRODUCTS,
  GET_SEARCH_STORE_AVAILABLE,
  GET_SEARCH_MESSAGES,
  GET_SEARCH_BUYER,
  POST_FINISH_ORDER_STORE
} from '../../config'
import titleCard from '../useful/titleCard.vue'
import AppTitleDialog from '../useful/titleDialog.vue'
import AvatarLetter from '../general/avatarLetter.vue'
import OrderEmail from '../order/emailOrder.vue'
import QuillEditorFormat from '../useful/quillEditorFormat.vue'

export default {
  name: 'CheckoutCash',
  metaInfo: {
    title: 'Nuevo pedido'
  },
  components: {
    titleCard,
    AvatarLetter,
    AppTitleDialog,
    OrderEmail,
    QuillEditorFormat
  },
  props: ['userID'],
  data() {
    return {
      orderID: 0,
      orderEmail: null,
      orderDialog: false,
      steps: [1],
      states: [],
      schedules: [],
      listStateStatus: [],
      selectedCommunesClose: [],
      productsAll: [],
      products: [],
      listTypesAddress: [],
      listUser: [],
      storesRetirements: [],
      latLngOrder: {},
      center: { lat: -33.4262, lng: -70.6429 },
      typesAddressSelect: {},
      addressSelection: {},
      productIndex: null,
      searchTextEmail: null,
      searchText: null,
      searchProduct: null,
      scheduleShipping: false,
      searchTextProduct: null,
      typeSearch: false,
      dialogOrderPre: false,
      dialogTypeAddress: false,
      dialogUser: false,
      dialogComentario: false,
      dialogMessage: false,
      hover: {
        type: false,
        shipping: false,
        state: false,
        dateShipping: false,
        schedule: false,
        payment: false,
        store: false,
        address: false
      },
      edit: {
        type: false,
        shipping: false,
        state: false,
        dateShipping: false,
        schedule: false,
        payment: false,
        store: false,
        address: false
      },
      typesAddress: [
        { id: 'DrParticular', name: 'Particular' },
        { id: 'CrClinica', name: 'Hospital, Clinica' },
        { id: 'DrEmpresa', name: 'Empresa' },
        { id: 'CrVelatorio', name: 'Iglesia, Velatorio' }
      ],
      headers: [
        {
          text: 'Nombre',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Cantidad',
          align: 'right',
          sortable: false,
          value: 'quantity'
        },
        {
          text: 'Valor',
          align: 'right',
          sortable: false,
          value: 'value'
        },
        {
          text: 'Total',
          align: 'right',
          sortable: false,
          value: 'total'
        },
        { text: '', value: 'actions' }
      ],
      zoom: 16,
      place: {},
      windowHeight: window.innerHeight - (window.innerHeight * 80) / 100,
      options: {
        styles: [
          {
            featureType: 'water',
            stylers: [
              {
                saturation: 43
              },
              {
                lightness: -11
              },
              {
                hue: '#0088ff'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
              {
                hue: '#ff0000'
              },
              {
                saturation: -100
              },
              {
                lightness: 99
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#808080'
              },
              {
                lightness: 54
              }
            ]
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ece2d9'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ccdca1'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#767676'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#ffffff'
              }
            ]
          },
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry.fill',
            stylers: [
              {
                visibility: 'on'
              },
              {
                color: '#b8cb93'
              }
            ]
          },
          {
            featureType: 'poi.park',
            stylers: [
              {
                visibility: 'on'
              }
            ]
          },
          {
            featureType: 'poi.sports_complex',
            stylers: [
              {
                visibility: 'on'
              }
            ]
          },
          {
            featureType: 'poi.medical',
            stylers: [
              {
                visibility: 'on'
              }
            ]
          },
          {
            featureType: 'poi.business',
            stylers: [
              {
                visibility: 'simplified'
              }
            ]
          }
        ],
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      },
      messages: [],
      message: ''
    }
  },
  computed: {
    methodsPayment() {
      return this.$store.getters.getMethodsPaymet
    },
    stateDetail() {
      const statusActive = this._.filter(this.listStateStatus, ['id', this.model])
      return statusActive[0]
    },
    order: {
      set(text) {
        console.log('order => ', text)
        this.$store.dispatch('setNewOrder', text)
      },
      get() {
        console.log(this.$store.getters.getNewOrder)
        return this.$store.getters.getNewOrder
      }
    },
    orderTotal() {
      let total = 0
      if (this._.size(this.order.schedule) > 0) {
        total += this.order.schedule.price
      }
      if (this.order.products.length > 0) {
        this.order.products.forEach(element => {
          total += parseInt(element.total, 10)
        })
      }
      return total
    },
    orderProductos() {
      let total = 0
      if (this.order.products.length > 0) {
        this.order.products.forEach(element => {
          total += parseInt(element.total, 10)
        })
      }
      return total
    },
    addressComplete() {
      if (this.order.address.street !== null) {
        return `${this.order.address.street} ${this.order.address.number}, ${this.order.state.name}`
      }
      return this.order.state.name
    }
  },
  watch: {
    userID(e) {
      this.checkUserRoute()
    },
    'order.type': function() {
      this.checkStep()
    },
    'order.shipping': function() {
      this.checkStep()
    },
    'order.schedule': function() {
      this.checkStep()
    },
    'order.store': function() {
      this.checkStep()
    },
    'order.dateShipping': function(e) {
      if (this.order.shipping !== e) {
        this.searchSchedule()
        this.order.schedule = {}
      }
      if (!this.order.shipping) {
        this.searchSchedule()
      }
      this.checkStep()
      this.productsAll = []
      if (e !== null) {
        this.searchsProducts()
      }
      this.edit.dateShipping = false
    },
    'order.state': function() {
      this.searchSchedule()
      this.checkStep()
    },
    'order.address.type': function(e) {
      console.log('order.address.type =>', e)
      if (e === 'CrClinica' || e === 'CrVelatorio') {
        this.$http({
          method: 'post',
          url: GET_API,
          params: {
            p: 'searchTypeAddress',
            type: e,
            stateID: this.order.state.id
          }
        }).then(res => {
          this.listTypesAddress = res.data.json
        })
      }
    },
    typesAddressSelect(e) {
      this.order.address.street = e.street
      this.order.address.number = e.number
      this.order.address.more = e.name
      this.setLatLngOrder({
        lat: e.lat,
        lng: e.lng
      })
    },
    addressSelection(e) {
      console.log('addressSelection =>', e)
      this.order.address.id = e.id
      this.order.address.name = `${e.name} ${e.lastname}`
      this.order.address.street = e.street
      this.order.address.number = e.number
      this.order.address.more = e.more
      this.order.address.type = e.type
      if (e.latitude !== undefined && e.longitude !== undefined && e.latitude !== 0 && e.longitude !== 0) {
        this.order.address.latitude = e.latitude
        this.order.address.longitude = e.longitude
        this.setLatLngOrder({
          lat: e.latitude,
          lng: e.longitude
        })
        this.edit.address = false
        this.checkStep()
      }
    }
  },
  mounted() {
    this.searchStatesSchedule()
    if (
      this.order.type !== null ||
      this.order.shipping !== null ||
      this.order.dateShipping !== null ||
      this._.size(this.order.state) > 0 ||
      this._.size(this.order.paymentMethod) > 0 ||
      this._.size(this.order.schedule) > 0 ||
      this.order.products.length > 0
    ) {
      this.dialogOrderPre = true
      this.searchsProducts()
      this.searchsStoreAvaibles()
      if (!this.order.shipping) {
        this.searchSchedule()
      }
      if (this._.size(this.order.store) > 0) {
        this.setSchedules(this.order.store.schedule.horarios, this.order.store.schedule.shipping)
      }
    }
    this.checkStep()
    this.checkUserRoute()
  },
  created() {
    this.searchInputUser = this._.debounce(function() {
      this.$http
        .get(GET_SEARCH_BUYER, {
          params: {
            email: this.searchTextEmail
          }
        })
        .then(res => {
          this.listUser = res.data
        })
    }, 800)
    this.searchInputProduct = this._.debounce(function() {
      console.log(this.productsAll)
      const search = this.searchTextProduct
      const result = this._.filter(this.productsAll, item => {
        const res = this._.includes(this._.lowerCase(!this.typeSearch ? item.name : item.id), this._.lowerCase(search))
        return res
      })
      this.products = []
      if (search === '') {
        this.products = []
      } else if (result.length > 0) {
        this.products = result
      }
    }, 300)
  },
  methods: {
    async searchStatesSchedule() {
      try {
        const res = await this.$http.get(GET_STATUS_STORE, {
          params: {
            all: true
          }
        })
        this.listStateStatus = res.data
        this.selectedCommunesClose = []
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async searchSchedule() {
      if (this.order.dateShipping !== null && this._.size(this.order.state) > 0 && !this.order.shipping) {
        try {
          const res = await this.$http.get(GET_SEARCH_SCHEDULE, {
            params: {
              dateShipping: this.order.dateShipping,
              stateId: this.order.state.id,
              shipping: this.order.shipping
            }
          })
          this.setSchedules(res.data.horarios, res.data.shipping)
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      } else if (this._.size(this.order.store) > 0) {
        this.setSchedules(this.order.store.schedule.horarios, this.order.store.schedule.shipping)
      }
    },
    async searchsProducts() {
      if (this.order.dateShipping !== null && this._.size(this.order.state) > 0) {
        try {
          const res = await this.$http.get(GET_SEARCH_PRODUCTS, {
            params: {
              dateShipping: this.order.dateShipping,
              stateId: this.order.state.id,
              schedule: this.order.schedule
            }
          })
          console.log(res)
          this.productsAll = res.data
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    async searchsStoreAvaibles() {
      try {
        const res = await this.$http.get(GET_SEARCH_STORE_AVAILABLE)
        this.storesRetirements = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async openMessage() {
      try {
        this.dialogMessage = !this.dialogMessage
        if (this.dialogMessage) {
          const res = await this.$http.get(GET_SEARCH_MESSAGES)
          if (res.data) {
            this.messages = res.data
          }
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async checkUserRoute() {
      try {
        const { userID } = this
        if (userID) {
          const res = await this.$http.get(GET_SEARCH_BUYER, {
            params: {
              email: this.searchTextEmail,
              userID
            }
          })
          const user = res.data
          this.handleClickUser(user[0])
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async finishOrderAction() {
      this.$http({
        method: 'post',
        url: POST_FINISH_ORDER_STORE,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: this.$qs.stringify({ p: 'finishOrderStore', order: this.order })
      })
        .then(res => {
          this.orderID = res.data.id
          setTimeout(() => {
            this.orderEmail = this.order.user.email
            this.orderDialog = true
          }, 1000)
        })
        .catch(() => {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        })
    },
    addComment(item) {
      this.productIndex = item.index
      this.dialogComentario = true
    },
    filterAddresses() {
      if (this.order.user.addresses !== undefined) {
        if (this.order.user.addresses.length > 0) {
          const stateID = this.order.state.id
          return this._.filter(this.order.user.addresses, ['state', stateID])
        }
      }
      return []
    },
    handleClickUser(user) {
      this.order.user.id = user.id
      this.order.user.name = this.$options.filters.toHTML(user.name)
      this.order.user.phone = user.phone
      this.order.user.email = user.email
      this.order.user.addresses = user.addresses
      this.order.user.status = user.status
      this.order.user.alert = user.alert
      this.dialogUser = false
    },
    finishOrder() {
      const resp = {
        error: false,
        msj: null
      }

      if ((this.order.shipping === 0 || this.order.shipping === 1) && parseInt(this.steps.length, 10) < 7) {
        resp.error = true
        resp.msj = 'Hay pasos incompletos'
      } else if (this.order.user.name === null || this.order.user.name === '') {
        resp.error = true
        resp.msj = 'Debe ingresar un nombre para el cliente'
      } else if (this.order.user.email === null || this.order.user.email === '') {
        resp.error = true
        resp.msj = 'Debe ingresar un email para el cliente'
      } else if (this.order.user.phone === null || this.order.user.phone === '') {
        resp.error = true
        resp.msj = 'Debe ingresar un teléfono para el cliente'
      } else if (this.order.user.phone === null || this.order.user.phone === '') {
        resp.error = true
        resp.msj = 'Debe ingresar un teléfono para el cliente'
      } else if (this.order.products.length === 0) {
        resp.error = true
        resp.msj = 'Debe ingresar al menos un producto'
      }
      if (!resp.error) {
        this.finishOrderAction()
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: resp.msj })
      }
    },
    checkCash(id) {
      if (this.order.paymentMethod.id === 6) {
        this.$http({
          method: 'post',
          url: GET_API,
          params: {
            p: 'insertCashOrder',
            id
          }
        }).then(res => {
          console.log(res)
        })
      }
    },
    finish() {
      this.checkCash(this.orderID)
      this.orderDialog = false
      this.clearOrder()
      this.$router.push({ name: 'orderIndex', params: { id: this.orderID } })
    },
    dragend(e) {
      const position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
      this.setLatLngOrder(position)
    },
    setPlace(e) {
      const position = {
        lat: e.geometry.location.lat(),
        lng: e.geometry.location.lng()
      }
      this.setLatLngOrder(position)
    },
    setLatLngOrder(position) {
      this.latLngOrder = position
      this.center = position
      this.zoom = 16
    },
    checkStep() {
      let steps = []
      steps.push(1)
      steps = this._.union(1, steps)

      if (this.order.type !== null) {
        steps.push(2)
        steps = this._.union(2, steps)
      }
      if (this.order.shipping !== null) {
        steps.push(3)
        steps = this._.union(3, steps)
      }
      if (this._.size(this.order.state) > 0) {
        steps.push(4)
        steps = this._.union(4, steps)
      }
      if (this._.size(this.order.store) > 0) {
        steps.push(4)
        steps = this._.union(4, steps)
      }
      if (this.order.dateShipping !== null) {
        steps.push(5)
        steps = this._.union(5, steps)
      }
      if (this._.size(this.order.schedule) > 0) {
        steps.push(6)
        if (this.order.shipping === 1) {
          steps.push(7)
        }
        steps = this._.union(6, steps)
      }
      if (this.order.address.latitude !== null && this.order.address.longitude !== null) {
        steps.push(7)
        steps = this._.union(7, steps)
      }
      this.steps = steps
    },
    searchInputHandle() {
      const search = this.searchText
      const result = this._.filter(this.listStateStatus, item => {
        const res = this._.includes(this._.lowerCase(item.name), this._.lowerCase(search))
        return res
      })
      this.states = []
      if (search === '') {
        this.states = []
      } else if (result.length > 0) {
        this.states = result
      }
    },
    handleType(type) {
      this.order.type = type
      this.hover.type = false
      this.edit.type = false
    },
    clearOrder() {
      this.$store.dispatch('setNewOrder', {
        typeShipping: null,
        message: null,
        type: null,
        shipping: null,
        state: {},
        dateShipping: null,
        paymentMethod: {},
        schedule: {},
        products: [],
        store: {},
        address: {
          id: null,
          name: null,
          phone: null,
          street: null,
          number: null,
          latitude: null,
          longitude: null,
          type: null,
          typeHospital: null
        },
        user: {
          id: null,
          name: null,
          email: null,
          phone: null,
          status: true,
          alert: null
        }
      })
      this.dialogOrderPre = false
    },
    addProduct(item) {
      let price = item.price.old
      const product = {}
      product.type = 0
      product.quantity = 1
      product.detail = item
      if (item.price.oferta) {
        price = item.price.new
      }
      product.unity = price
      product.total = price
      this.order.products.push(product)
      this.products = []
    },
    handleClickShipping(type) {
      if (type !== this.order.shipping) {
        this.order.shipping = type
        this.order.store = {}
        this.order.state = {}
        this.order.dateShipping = null
        this.order.schedule = {}
      }
      if (type === 1) {
        this.order.store = {}
        this.storesRetirements = []
        this.searchsStoreAvaibles()
      }
      this.hover.shipping = false
      this.edit.shipping = false
    },
    handleClickStore(store) {
      this.order.store = store
      this.order.state = store.state
      this.hover.store = false
      this.edit.store = false
      this.setSchedules(store.schedule.horarios, store.schedule.shipping)
    },
    handleClickState(state) {
      if (this.order.state !== state) {
        this.order.state = state
        this.order.dateShipping = null
        this.order.schedule = {}
      }
      this.edit.state = false
    },
    setSchedules(schedules, active) {
      this.scheduleShipping = active
      this.schedules = schedules === undefined ? [] : schedules
    },
    insertAddress() {
      let msj = ''
      console.log('insertAddress =>', this.order.address.name)
      if (this.order.address.name === '' || this.order.address.name === null) {
        msj = 'Nombre destinatario es obligatorio'
      } else if (this.order.address.type === '' || this.order.address.type === null) {
        msj = 'Tipo direccion es obligatorio'
      } else if (this.order.address.street === '' || this.order.address.street === null) {
        msj = 'Dirección es obligatoria'
      } else if (this.order.address.number === '' || this.order.address.number === null) {
        msj = 'Número de calle es obligatorio'
      }
      if (msj === '') {
        this.order.address.latitude = this.latLngOrder.lat
        this.order.address.longitude = this.latLngOrder.lng
        this.edit.address = false
        this.checkStep()
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: msj })
      }
    },
    changeQuantityProduct(prop, value) {
      this.order.products[prop.index].total =
        this.order.products[prop.index].unity * this.order.products[prop.index].quantity
    },
    addAditional() {
      const product = {
        type: 1,
        quantity: 1,
        detail: {
          id: 0,
          name: 'Adicional',
          img: {
            url: ''
          }
        },
        unity: 0,
        total: 0
      }
      this.order.products.push(product)
    },
    deleteItem(item) {
      const products = []
      this.order.products.forEach((element, index) => {
        if (item.index !== index) {
          products.push(element)
        }
      })
      this.order.products = products
      this.$store.dispatch('setConfirm', { active: false })
    },
    deleteItemAction(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar producto?',
        description: '',
        action: () => this.deleteItem(item)
      })
    },
    deleteUser() {
      this.order.user = {
        id: null,
        name: null,
        email: null,
        phone: null,
        status: true,
        alert: null
      }
    }
  }
}
</script>

<style>
.gmap {
  position: relative;
}
.input-search {
  outline: none;
  padding: 12px 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px !important;
}
.input-search:focus {
  border: 1px solid #f44336;
}
.input-search::placeholder {
  font-style: 'italic';
  color: rgb(143, 143, 143);
  padding: 10px 2px 10px 2px;
}
.map-icon-label .map-icon {
  font-size: 24px;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
  white-space: nowrap;
}
.label {
  background: #000;
}
.cut-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
