<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <AppTitleDialog :title="`Pedido ${id}`" close :close-action="close"></AppTitleDialog>
      <v-container class="pa-3" grid-list-lg>
        <v-layout row wrap>
          <v-flex xs12>
            <quill-editor ref="myQuillEditor" v-model="orderMail" :options="editorOption"> </quill-editor>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="orderEmailConfirmation"
              label="Email donde se enviara..."
              hint="Si es otro email, ingreselo"
              :placeholder="email"
              persistent-hint
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-checkbox v-model="copy" color="primary" class="ma-0 pa-0" label="Enviar copia a ventas@rosalinda.cl">
            </v-checkbox>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="back" flat @click.stop="close"> Volver </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat :loading="loadingDialog" :disabled="loadingDialog" @click.native="orderEmailSend">
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppTitleDialog from '../useful/titleDialog.vue'
import { GET_API, GET_ORDER_EMAIL } from '../../config'

export default {
  components: { AppTitleDialog },
  props: {
    id: Number,
    finish: Function,
    email: String,
    close: Function,
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orderMail: '',
      orderEmailConfirmation: '',
      loadingDialog: false,
      editorOption: {
        placeholder: 'Texto acá'
      },
      copy: true
    }
  },
  watch: {
    dialog(e) {
      if (e) {
        this.getOrder()
      }
    }
  },
  methods: {
    async getOrder() {
      try {
        const res = await this.$http.get(`${GET_ORDER_EMAIL}/${this.id}`)
        this.orderMail = this.$options.filters.toHTML(res.data)
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async orderEmailSendAction() {
      try {
        this.loadingDialog = true
        this.$store.dispatch('setConfirm', { active: false })
        await this.$http.post(`${GET_ORDER_EMAIL}/${this.id}`, {
          orderMail: this.orderMail,
          email: this.orderEmailConfirmation,
          copy: this.copy
        })
        this.loadingDialog = false
        this.$store.dispatch('changeSnack', { active: true, text: 'Email enviado' })
        this.finish()
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    orderEmailSend() {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: 'Enviar orden?',
        description: 'La orden se enviara al cliente',
        action: this.orderEmailSendAction
      })
    }
  }
}
</script>

<style></style>
