import { render, staticRenderFns } from "./index.vue?vue&type=template&id=428e933b&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VAutocomplete } from 'vuetify/lib'
import { VAvatar } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VDatePicker } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VEditDialog } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAction } from 'vuetify/lib'
import { VListTileAvatar } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileSubTitle } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VRadio } from 'vuetify/lib'
import { VRadioGroup } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VSubheader } from 'vuetify/lib'
import { VSwitch } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarTitle } from 'vuetify/lib'
installComponents(component, {VAlert,VAutocomplete,VAvatar,VBtn,VCard,VCardActions,VCardText,VChip,VContainer,VContent,VDataTable,VDatePicker,VDialog,VDivider,VEditDialog,VFlex,VIcon,VImg,VLayout,VList,VListTile,VListTileAction,VListTileAvatar,VListTileContent,VListTileSubTitle,VListTileTitle,VRadio,VRadioGroup,VSelect,VSpacer,VSubheader,VSwitch,VTextField,VTextarea,VToolbar,VToolbarTitle})
